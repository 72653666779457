import React from "react";
import { Navigate } from "react-router-dom";
import { trialApply, freeTrial, exportReport, apply, paidService ,exportReportApply } from "../constants/module";
import { TrialApply } from "pages/TrialApply/trialApply";
import { Apply } from "pages/Apply/apply";
import { FreeTrial } from "pages/FreeTrial/freeTrial";
import { PaidService } from "pages/PaidService/paidService";
import { ExportReport } from "pages/ExportReport/exportReport";
import { ExportReportApply } from "pages/ExportReportApply/exportReportApply";
import "../default.less";

export const routes = [
  {
    path: trialApply.route,
    element: <TrialApply />,
  },
  {
    path: apply.route,
    element: <Apply />,
  },
  {
    path: freeTrial.route,
    element: <FreeTrial />,
  },
  {
    path: paidService.route,
    element: <PaidService />,
  },
  {
    path: exportReport.route,
    element: <ExportReport />,
  },
  {
    path: exportReportApply.route,
    element: <ExportReportApply />,
  },
  {
    path: "*",
    element: <Navigate to={trialApply.route} replace={true} />,
  },
];
