import React from "react";
import { useTranslation } from "react-i18next";
import { useRoutes, useLocation } from "react-router-dom";
import { routes } from "./routes";
import { ConfigProvider, Layout as AntdLayout, Grid } from "antd";
import "./App.less";
import en_US from "antd/lib/locale/en_US";
import zh_TW from "antd/lib/locale/zh_TW";

import { ReportProvider } from "contexts/ReportContext";

const { useBreakpoint } = Grid;

function App() {
  const { i18n } = useTranslation();

  const pages = useRoutes(routes);
  const state = useLocation();
  const screens = useBreakpoint();

  const showFooterBg = state.pathname === "/" + routes[0].path; 

  let locale;
  switch (i18n.language) {
    case "en-US":
      locale = en_US;
      break;
    case "zh-TW":
      locale = zh_TW;
      break;
    default:
      locale = zh_TW;
      break;
  }
  ConfigProvider.config({
    theme: {
      primaryColor: "#5ECB71",
    },
  });
  return (
    <ReportProvider>
      <ConfigProvider locale={locale}>
        <AntdLayout style={screens.xl ? { height: "100vh" } : null}>
          <div className="appContent">
            {pages}
            {showFooterBg ? <div className="footerBG" /> : null}
          </div>
        </AntdLayout>
      </ConfigProvider>
    </ReportProvider>
  );
}

export default App;
